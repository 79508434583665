import React from 'react';
import 'rc-collapse/assets/index.css';
import FAQKID from '@/assets/timestables/FAQ/FAQKid.png';
import FAQAdult from '@/assets/homepageV2/FAQ/FAQAdult.png';
import QuestionMark from '@/assets/timestables/FAQ/QuestionMark.png';
import QuestionMarkMobile from '@/assets/timestables/FAQ/QuestionMarkMobile.png';
import Image from 'next/image';
import FAQItem from './FAQItem';
import { TimesTablesBottomCTAButton, TimesTablesHeaderCTAButton } from '@/analytics/events';
import CTARow from '@/components/TimesTables/CTARow';

const TimesTableFAQ = ({ faqs = [], minusIcon = null, plusIcon = null, showCta = false, showQuestionMark = false, homePage = false }) => {
    return (
        <div className='relative mx-auto max-w-[1500px] w-full px-4 sm:px-8 lg:px-[70px] pb-10 sm:pb-[90px] lg:pb-[120px]'>
            <div className='bg-[#5A41B4] rounded-[32px] w-full max-w-[900px] xl:max-w-[1000px] h-full mx-auto times-tables-icons:mx-0 times-tables-icons:ml-[200px] xl:ml-[220px] relative py-[60px] px-4 md:px-[110px]'>
                <div className='hidden times-tables-icons:block absolute top-12 left-[-18vh]'>
                    <Image src={homePage ? FAQAdult : FAQKID} className='max-w-full h-auto' />
                </div>

                {showQuestionMark && (
                    <div className='hidden times-tables-icons:block absolute -top-[5.25rem] right-[-1vh] z-10'>
                        <Image src={QuestionMark} className='max-w-full h-auto' />
                    </div>
                )}

                <div className='block times-tables-icons:hidden absolute -top-[2.25rem] right-[-1vh] z-10 '>
                    <Image src={QuestionMarkMobile} className='max-w-full h-auto' />
                </div>
                <div className='text-white text-[60px] pl-3 sm:pl-0'>
                    FAQ<span className='font-Gloria text-[#64DFE5]'>s</span>
                </div>
                <div>
                    {faqs.map((faq) => (
                        <FAQItem key={faq.title} faq={faq} />
                    ))}
                </div>
            </div>

            {showCta && <CTARow eventName={TimesTablesHeaderCTAButton} path='/timestables/checkout' />}
        </div>
    );
};

export default TimesTableFAQ;
