import React from 'react';
import { TimesTablesBottomCTAButton } from '@/analytics/events';
import { useAnalytics } from '@/analytics/AnalyticsProvider';
import { useRouter } from 'next/router';

export const CTAButton = ({ eventName = '', path = '' }) => {
    const router = useRouter();
    const analytics = useAnalytics();
    // <div className='w-full flex flex-col items-center justify-center grow pt-2 md:pt-0 mt-10 xs:mt-10 md:mt-10 lg:mt-10 md:mb-5'>
    return (
        <>
            <div className='hidden xl:block'>
                <div className='flex justify-center w-full max-w-[290px]'>
                    <button
                        className='text-2xl lg:text-3xl xl:text-4xl relative bg-[#FFD100] hover:bg-[#ffe368] border-none rounded-[56px] text-[#1F0833] text-center font-semibold px-5 xl:px-10 py-[20px] '
                        // className='lg:bg-[red] text-2xl  3xl:text-5xl bg-[#FFD100] hover:bg-[#ffe368] border-none rounded-[56px] text-[#1F0833] text-center font-semibold px-5 py-[20px] w-[205px]'
                        onClick={(event) => {
                            analytics.track(eventName, event);
                            router.push(path, undefined, { shallow: true });
                        }}
                    >
                        Get Started
                    </button>
                </div>
            </div>

            <div className='block xl:hidden '>
                <div className='flex justify-center mx-auto max-w-[260px] sm:max-w-[290px] w-full'>
                    <div className='flex justify-center w-full'>
                        <button
                            className='min-w-[220px] text-2xl md:text-3xl lg:text-3xl relative bg-[#FFD100] hover:bg-[#ffe368] rounded-[56px] text-[#1F0833] font-semibold text-center w-full h-[56px] md:h-[68px] px-5   border-none '
                            onClick={(event) => {
                                analytics.track(eventName);
                                router.push(path, undefined, { shallow: true });
                            }}
                        >
                            Get Started
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};


export const CTARow = ({ eventName = '', path = '' }) => {
    const router = useRouter();
    const analytics = useAnalytics();
    return (
        <div className='w-full flex flex-col items-center justify-center grow pt-2 md:pt-0 mt-10 xs:mt-10 md:mt-10 lg:mt-10 md:mb-5'>
            <CTAButton eventName={eventName} path={path} />

            {/* <div className='text-center text-white font-light mt-3'>
                <span>Only $9.99 for</span>
                <br />
                <span>99 days of access</span>
            </div> */}

        </div>
    );
};

export default CTARow;
