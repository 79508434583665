import React from 'react';

export const RichTextBlock = ({ richText, heading = null, style = null }) => (
    <div className='rich-text' style={style}>
        <div className='content'>
            {heading && <h2 style={{ color: '#121651', fontSize: '42px' }}>{heading}</h2>}
            <div dangerouslySetInnerHTML={{ __html: richText }} />
        </div>
        <style jsx>{`
            .rich-text {
                padding: 40px 60px;
                background-color: #f9f9f9;

                .content {
                    //max-width: 992px;
                    margin: 0 auto;

                    h2 {
                        text-align: center;
                    }
                }


            }


            p {
                font-size: 18px;
                color: #4f4f4f;
            }

            ul {
                margin: 24px 0;
                list-style-image: url('/assets/icons/red-bullet.svg');

                li {
                    margin: 12px 0;
                    padding-left: 8px;
                    font-size: 18px;
                    color: #4f4f4f;
                }
            }

            @media (max-width: 992px) {
                .rich-text {
                    padding: 40px;
                }

                h2 {
                    font-size: 32px !important;
                    text-align: left !important;
                }
            }

            @media (max-width: 480px) {
                .rich-text {
                    padding: 40px 20px;
                }

                h2 {
                    font-size: 22px !important;
                }
            }
        `}</style>
    </div>
);
